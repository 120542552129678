import { animated, config, useSpring } from 'react-spring'
import { ContainerLg } from '/components/buildingBlocks/Container'
import { TitleTextAndCTAXxl } from '/components/buildingBlocks/TitleTextAndCTA'
import { Logo } from '/components/buildingBlocks/Logo'

import circle8group from '/images/logos/circle-8-light-red.raw.svg'

import styles from './Hero.css'

export function Hero({ layoutClassName }) {
  const { style } = useStaggerAnimationsCorporate()

  return (
    <ContainerLg {...{ layoutClassName }}>
      <section className={styles.component} data-style-context='dark'>
        <Logo layoutClassName={styles.logoLayout} />
        <div className={styles.content}>
          <Circle8GroupLogo animation={style} layoutClassName={styles.logoLayout} />
          <TitleTextAndCTAXxl
            title='Making talent flow across Europe'
            text='In only a few years, Circle8 has rapidly grown into one of Europe&#39;s largest IT talent matching companies, helping over 12,000 professionals and over 500 clients to grow and be all that they can be.'
            href='#our-brands'
            label='Meet our brands'
          />
        </div>
      </section>
    </ContainerLg>
  )
}

function Circle8GroupLogo({ animation, layoutClassName }) {
  return (
    <animated.div
      dangerouslySetInnerHTML={{ __html: circle8group }}
      role='presentation'
      className={cx(styles.componentCircle8GroupLogo, layoutClassName)}
      draggable="false"
      style={animation}
    />
  )
}

function useStaggerAnimationsCorporate() {
  const style = useSpring({
    from: { y: 25, opacity: 0.1 },
    to: { y: 0, opacity: 1 },
    config: config.slow
  })

  return { style }
}
